
import BButton from 'buetify/lib/components/button/BButton';
import BToast from 'buetify/lib/components/notices/toast/BToast';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'template-snackbar-example',
	components: {
		BToast,
		BButton
	}
});
